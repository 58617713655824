/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

$base-color: rgb(221 221 221 / 86.3%);
$ghost-color: #ecebebf1;
$animation-duration: 1000ms;
$gradient-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0, $ghost-color 40px, $base-color 80px);
  background-size: 80vw;
}

@keyframes ghost-lines {
  0% {
    background-position: -100px;
  }

  40% {
    background-position: 40vw;
  }

  100% {
    background-position: 60vw;
  }
}

@keyframes ghost-avatar {
  0% {
    background-position: -100px + $gradient-offset;
  }

  40%,
  100% {
    background-position: 140px + $gradient-offset;
  }
}

@mixin animate-ghost-avatar {
  @include background-gradient;

  animation: ghost-avatar $animation-duration infinite linear;
}

@mixin animate-ghost-line {
  animation: ghost-lines $animation-duration infinite linear;
}

.text-blur {
  filter: blur(3px);
}

.ghost-lines {
  background-color: gray;
  border-bottom: 3px solid white;
  border-radius: 4px;
  background-image: linear-gradient(
    90deg,
    rgb(221 221 221 / 86.3%) 0,
    rgb(236 235 235 / 94.5%) 40px,
    rgb(221 221 221 / 86.3%) 80px
  );
  background-size: 80vw;
  animation: ghost-lines 1000ms infinite linear;
}

.ghost-lines-50 {
  width: 50%;
}
