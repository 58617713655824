@use 'sass:color';
@use 'branding';

/**
 * @sass-export-section="primaryColors"
 */
$color-brand-purple-50: #fbf5fc;
$color-brand-purple-100: #e9ddec;
$color-brand-purple-200: #d4bbd8;
$color-brand-purple-300: #be99c5;
$color-brand-purple-400: #a977b1;
$color-brand-purple-500: #93559e;
$color-brand-purple-600: #76447e;
$color-brand-purple-700: #58335f;
$color-brand-purple-800: #3b223f;
$color-brand-purple-900: #2c192f;

/**
  * @sass-export-section="secondaryColors"
  */
$color-brand-turquoise-50: #f3f9fa;
$color-brand-turquoise-100: #cce6eb;
$color-brand-turquoise-200: #99cdd7;
$color-brand-turquoise-300: #66b4c2;
$color-brand-turquoise-400: #339bae;
$color-brand-turquoise-500: #00829a;
$color-brand-turquoise-600: #00687b;
$color-brand-turquoise-700: #004e5c;
$color-brand-turquoise-800: #00343e;
$color-brand-turquoise-900: #00272e;

/**
  * @sass-export-section="neutrals"
  */
$color-neutral-50: #f9f9fa;
$color-neutral-100: #e6e6ea;
$color-neutral-200: #d2d3d9;
$color-neutral-300: #b8bac3;
$color-neutral-400: #989ba8;
$color-neutral-500: #7f8393;
$color-neutral-600: #585d72;
$color-neutral-700: #313751;
$color-neutral-800: #202333;
$color-neutral-900: #0e0e14;

/**
  * Semantic colors
  * Green (confirmation)
  * @sass-export-section="semanticColorsGreenConfirmation"
  */
$color-brand-green-50: #f3faf7;
$color-brand-green-100: #d0ebdf;
$color-brand-green-200: #9ed7be;
$color-brand-green-300: #6ac39e;
$color-brand-green-400: #31af7d;
$color-brand-green-500: #069868;
$color-brand-green-600: #067e54;
$color-brand-green-700: #055c3d;
$color-brand-green-800: #033f2a;
$color-brand-green-900: #02271a;

/**
  * Semantic colors
  * Blue (informative)
  * @sass-export-section="semanticColorsBlueInformative"
  */
$color-brand-blue-50: #f0f4ff;
$color-brand-blue-100: #d9e2fc;
$color-brand-blue-200: #b0c2f7;
$color-brand-blue-300: #7693ea;
$color-brand-blue-400: #436bdb;
$color-brand-blue-500: #264dbc;
$color-brand-blue-600: #2141a1;
$color-brand-blue-700: #19327a;
$color-brand-blue-800: #00319a;
$color-brand-blue-900: #0a132f;

/**
  * Semantic colors
  * Yellow (caution)
  * @sass-export-section="semanticColorsYellowCaution"
  */
$color-brand-yellow-50: #fffce6;
$color-brand-yellow-100: #fef5b9;
$color-brand-yellow-200: #fce769;
$color-brand-yellow-300: #f3d216;
$color-brand-yellow-400: #e2bd03;
$color-brand-yellow-500: #c19c06;
$color-brand-yellow-600: #977402;
$color-brand-yellow-700: #6b5006;
$color-brand-yellow-800: #453208;
$color-brand-yellow-900: #2b1f03;

/**
  * Semantic colors
  * Orange
  * @sass-export-section="semanticColorsOrangeCaution"
  */
$color-brand-orange-50: #fff3e0;
$color-brand-orange-100: #ffe5bd;
$color-brand-orange-200: #ffcc7a;
$color-brand-orange-300: #ffad33;
$color-brand-orange-400: #f49106;
$color-brand-orange-500: #d87803;
$color-brand-orange-600: #a45904;
$color-brand-orange-700: #793f02;
$color-brand-orange-800: #4f2402;
$color-brand-orange-900: #311702;

/**
  * Semantic colors
  * Red (error)
  * @sass-export-section="semanticColorsRedError"
  */
$color-brand-red-50: #fff3f0;
$color-brand-red-100: #fdd8ce;
$color-brand-red-200: #f9ae9a;
$color-brand-red-300: #f28264;
$color-brand-red-400: #e65c37;
$color-brand-red-500: #d43f17;
$color-brand-red-600: #b2320f;
$color-brand-red-700: #812308;
$color-brand-red-800: #561001;
$color-brand-red-900: #370a01;

/**
  * Semantic colors
  * Main colors
  * @sass-export-section="mainColors"
  */
$color-primary: $color-neutral-900;
$color-secondary: $color-brand-turquoise-500;
//
$color-jobs: branding.$color-jobs-brand-60-base;
$color-jobs-active: branding.$color-jobs-brand-80;
$color-jobs-hover: branding.$color-jobs-brand-70;
//
$color-jobscout24: branding.$color-jobscout24-brand-60-base;
$color-jobscout24-active: branding.$color-jobscout24-brand-80;
$color-jobscout24-hover: branding.$color-jobscout24-brand-70;
//
$color-jobup: branding.$color-jobup-brand-50-base;
$color-jobup-active: branding.$color-jobup-brand-80;
$color-jobup-hover: branding.$color-jobup-brand-70;
//
$color-jobup-alternative: $color-jobup;
$color-jobup-active-alternative: $color-brand-green-900;
$color-jobup-hover-alternative: $color-brand-green-800;
//
$color-jobscout24-secondary: branding.$color-jobs-brand-70;
$color-jobscout24-secondary-active: branding.$color-jobs-brand-70;
$color-jobscout24-secondary-hover: branding.$color-jobs-brand-70;

/**
  * Shadows
  * @sass-export-section="shadows"
  */
$shadow-200:
  0 8px 8px -4px rgb(24 39 75 / 8%),
  0 4px 6px -4px rgb(24 39 75 / 12%);
$shadow-400:
  0 8px 24px -4px rgb(24 39 75 / 8%),
  0 6px 12px -6px rgb(24 39 75 / 12%);
$shadow-600:
  0 12px 42px -4px rgb(24 39 75 / 12%),
  0 8px 18px -6px rgb(24 39 75 / 12%);
$shadow-800:
  0 18px 88px -4px rgb(24 39 75 / 14%),
  0 8px 28px -6px rgb(24 39 75 / 12%);

/**
  * Feedback colors
  * @sass-export-section="feedback-colors"
  */
$color-success: $color-brand-green-500;
$color-information: $color-brand-blue-500;
$color-warning: $color-brand-yellow-600;
$color-error: $color-brand-red-500;
$color-disabled: $color-neutral-100;
$color-disabled-text: $color-neutral-400;

/* TO BE REFACTOR */

$grey-background: rgb(67 70 77 / 100%);
$text-highlight: rgb(226 226 226 / 55%);
$font-text-color: rgb(0 0 0 / 60%);
$shade-light: rgb(0 0 0 / 12%);
// stylelint-disable-next-line scss/dollar-variable-pattern
$primary--color: $color-primary;
$color-light-gray: #e2e2e2;
$lighten-green: color.adjust(#4a905e, $lightness: 40%);
$lighten-purple: color.adjust(#92559d, $lightness: 40%);
$lighten-blue: color.adjust(#6d9dc5, $lightness: 40%);
$lighter-blue: #e1f9fd;
$light-color: $color-neutral-50;
$white-darken: #f4f4f4;
$white: #fff;
$gray-disabled-light: #e9e9e9;
$gray-disabled: #bababa;
$gray-darken: #707372;
$gray-lighter: #e2e2e2;
$gray-light: #e2e2e2;
$gray-tertiary: #fbfbfb;
$gray-secondary: #e9e2e1;
$gray-primary: #999;
$red-error: #ca3126;
$red-lighter: #ffc9c8;
$red-darken: #b62824;
$green-lighter: #e0fce8;
$green-light: #00bd8c;
$green-darken: #00784b;
$purple-lighter: #ebdbee;
$purple-darken: #93559d;
$purple: #92559d;
$blue: #6d9dc5;
$red: #ee5151;
$green: #4a905e;
$black: #2a2a2b;
