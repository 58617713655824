@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/platform-colors';

button.ui-button.button-color {
  @include mixins.button-colors(platform-colors.$platform-colors);

  &-secondary-jobs {
    border-radius: 1.5rem;
  }
}

@mixin job-style($job, $color, $alternative: null) {
  .#{$job} {
    color: $color;

    &-background {
      background-color: $color;
    }

    &-border {
      border: 1px solid $color;
    }

    @if $alternative {
      &.alternative {
        color: $alternative;

        &-background {
          background-color: $alternative;
        }

        &-border {
          border: 1px solid $alternative;
        }
      }
    }
  }
}

@include job-style('job-scout-24', colors.$color-jobscout24);
@include job-style('jobs', colors.$color-jobs);
@include job-style('jobup', colors.$color-jobup, colors.$color-jobup-alternative);
