/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

.avatar-xs {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 7px;
  background-size: cover;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
}
