/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';

$purple: colors.$purple;
$mk-panel__padding: 10px;
$mk-panel__border-radius: 25px;

.mk-panel {
  padding: $mk-panel__padding;
  border-radius: $mk-panel__border-radius;

  &__error {
    background: rgba($purple, 0.2);
    color: $purple;
  }
}
