/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';
@use 'styles/variables';
@use 'styles/mixins';

$modal-padding: variables.$padding-m;
$modal-no-padding: variables.$padding-0;
$modal-button-margin: variables.$margin-xl;
$modal-title-background: colors.$white;
$modal-title-color: colors.$color-neutral-800;
$modal-desktop-border-radius: variables.$box-default-radius;
$diag-content-padding: variables.$padding-xs variables.$padding-xxxl;
$diag-content-margin: variables.$margin-m - variables.$margin-xl variables.$margin-0 - variables.$margin-xl;
$diag-actions-margin: variables.$margin-l variables.$margin-0 variables.$margin-0 variables.$margin-0;
$gray-primary: colors.$gray-primary;

.cdk-overlay-container {
  z-index: variables.$z-index-cdk-overlay;
}

body.dialog-prevail .cdk-overlay-container {
  z-index: variables.$z-index-max;
}

.update-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
  }
}

.app-preview-dialog {
  @extend %mkp-dialog;

  .mat-mdc-dialog-content {
    padding: variables.$padding-xxxl;

    @media (max-width: variables.$media-breakpoint-tablet) {
      padding: variables.$padding-l;
      height: 100%;
      max-height: 100%;
    }
  }
}

.mood-image-dialog {
  width: 650px;
  height: 770px;
}

.skp-dialog {
  width: 100%;
  max-width: 100% !important;
  height: 100vh;

  .mat-mdc-dialog-container {
    padding: 0;
  }

  .mat-mdc-dialog-actions,
  .mat-mdc-dialog-content {
    margin: 0;
    box-sizing: border-box;
  }

  @include mixins.for-tablet-landscape-up {
    max-width: 42rem !important;
    height: auto;
  }
}

%mkp-dialog,
.mkp-dialog,
.mkp-dialog-large {
  &.modal-info {
    max-width: 690px !important;
  }

  width: 100%;
  max-width: 100% !important;
  height: 100vh;

  .dialog-container {
    display: flex;
    position: relative;
    overflow-y: auto;

    .mat-mdc-checkbox {
      padding: variables.$padding-xs variables.$padding-0;

      // text-transform: capitalize;
    }
  }

  .mat-mdc-dialog-title,
  .mat-mdc-dialog-actions {
    padding: $modal-padding;
    background: colors.$white-darken;
  }

  .mat-mdc-dialog-actions {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    bottom: 25px;
    justify-content: flex-end;

    button:not(.ui-button) {
      margin: $modal-no-padding $modal-button-margin;

      &:not([disabled]) {
        cursor: pointer;
      }

      &:disabled,
      &[disabled] {
        cursor: not-allowed;
      }
    }

    button:not(.ui-button):not(.filled) {
      background: none;
      border: none;
      color: colors.$primary--color;
      cursor: pointer;
      font-size: 0.875rem;
      text-transform: uppercase;

      &:focus {
        outline: 0;
      }

      &:disabled {
        color: colors.$gray-disabled !important;
      }
    }
  }

  .mat-mdc-dialog-container {
    overflow: hidden;

    // padding: $modal-no-padding !important;
    border-radius: 0 !important;

    .mat-mdc-dialog-title {
      color: $modal-title-color;
      background: $modal-title-background !important;
      padding-left: $modal-padding !important;
      margin-bottom: 0;

      .title-icon {
        cursor: pointer;
      }
    }
  }

  .mat-mdc-dialog-content {
    display: flex;

    // height: auto;
    padding: $modal-padding;
    margin: $modal-no-padding;
    height: calc(100vh - 50px);
    max-height: 80vh;

    // justify-content: center;
    flex-direction: column;
  }

  &.mkp-modal {
    width: 100%;
    max-width: 500px !important;
    height: auto;

    .mat-mdc-dialog-container {
      // padding: $modal-no-padding !important;
      border-radius: $modal-desktop-border-radius !important;
    }

    .mat-mdc-dialog-content {
      height: auto;
      overflow-y: auto;

      // padding: $diag-content-padding; // removed padding and margin
      // margin: $diag-content-margin; // to display the scroll
      max-height: 65vh;
    }

    .mat-mdc-dialog-actions {
      padding: inherit;
      background: none;
      margin: $diag-actions-margin;
    }
  }

  &.no-bg {
    .mat-mdc-dialog-container {
      .mat-mdc-dialog-title {
        font-weight: 300;
        color: $gray-primary;
        background: none !important;
      }
    }

    &.no-scroll {
      .mat-mdc-dialog-content {
        overflow: hidden;
      }
    }
  }
}

@media (min-width: variables.$media-breakpoint-tablet) {
  .update-dialog .mat-mdc-dialog-container {
    max-width: 22rem;
    max-height: 40rem;
  }

  .mkp-dialog {
    max-width: 500px !important;
  }

  .mkp-dialog-large {
    max-width: 650px !important;
  }

  .app-preview-dialog {
    max-width: 60rem !important;
  }

  .mkp-dialog,
  .mkp-dialog-large {
    width: 100%;
    height: auto;

    .mat-mdc-dialog-container {
      // padding: $modal-no-padding !important;
      border-radius: $modal-desktop-border-radius !important;
    }

    .mat-mdc-dialog-content {
      height: auto;
      overflow-y: auto;
      max-height: 65vh;
    }

    .mat-mdc-dialog-actions {
      padding: inherit;
      background: none;
      margin: $diag-actions-margin;
      position: inherit;

      button.ui-button {
        margin: $modal-no-padding $modal-button-margin;
      }
    }
  }
}

/** OVERRIDE, TO BE CHECKED */

.mat-mdc-dialog-component-host {
  padding: $modal-padding;
}
