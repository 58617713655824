/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';
@use 'styles/variables';

$editor-margin: variables.$margin-0 variables.$margin-xs variables.$margin-0 variables.$margin-xs;

.editor-container {
  margin: $editor-margin;
}

.ql-editor {
  font-size: variables.$normal-rem;
  height: 250px;
}

.ql-container {
  border-top-width: 0 !important;
  border-radius: 0 0 variables.$border-radius variables.$border-radius !important;
  overflow: hidden;
}

mkp-vacancy-description-form .ql-container {
  min-height: 150px;
  overflow: hidden;
}

.ql-toolbar {
  border: variables.$border-bottom !important;
  border-radius: variables.$border-radius variables.$border-radius 0 0 !important;
}
