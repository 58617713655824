/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';

$purple: colors.$purple;

.mat-mdc-snack-bar-container {
  min-height: auto !important;

  &.error {
    background: $purple;
  }

  &.success {
    background: $primary--color;
  }
}
