/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/variables';
@use 'styles/typography';
@use 'styles/functions';

html,
body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden auto;
}

html {
  overflow: hidden;
} // prevent over scroll on iOS

body {
  background: variables.$body-background-color;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  -ms-content-zooming: none;
  word-wrap: break-word;
  text-size-adjust: none;
  font-family: typography.$font-stack;
  font-size: variables.$global-font-size;
}

.layout-card {
  display: flex;
  flex-direction: column;
  background-color: colors.$white;
  border-radius: variables.$border-radius;
  box-shadow: variables.$box-shadow;
  padding: variables.$padding-m;

  @include mixins.for-desktop-up {
    position: relative;
    width: 100%;
    padding: functions.rem-calc(25px);
    background-color: colors.$white;
    border-radius: variables.$box-default-radius;
    box-shadow:
      0 1px 1px 0 rgb(0 0 0 / 14%),
      0 2px 1px -1px rgb(0 0 0 / 12%),
      0 1px 3px 0 rgb(0 0 0 / 20%);
  }
}

.link,
.simple-link,
.simple-link-uppercase {
  position: relative;
  cursor: pointer;
  color: colors.$primary--color;
  text-decoration: none;
}

.simple-link-uppercase {
  text-transform: uppercase;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}

.divider {
  width: 100%;
  margin: variables.$margin-l auto;
  height: 1px;
  background-color: colors.$shade-light;
}

.divider-empty {
  background-color: transparent;
}

.divider-small {
  margin: variables.$margin-xs auto;
}

.layout-full {
  width: 100%;
}

.layout-fourty {
  width: 40%;
}

.layout-fifty {
  width: 50%;
}

.layout-sixty {
  width: 60%;
}

.layout-quarter {
  width: 25%;
}

.layout-third {
  width: 33%;
}

.layout-seventy {
  width: 70%;
}

.layout-eighty {
  width: 80%;
}

.display-block {
  display: block !important;
}

.bg-white {
  background: colors.$white;
}

.bg-gray {
  background: colors.$gray-lighter;
}

.flex-order-1 {
  order: 1;
}

.flex-order-2 {
  order: 2;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.prefix-list-extended {
  width: 520px;
  max-height: 300px;
  margin-top: 19px;
  margin-left: -18px;
}

body.full-page-width {
  section.main {
    grid-template-areas: 'main-inner';
    grid-template-columns: 100%;
  }
}

/**
this defines .layout-paddings and .layout-margins, DRY
 */
@each $prop in (margin, padding) {
  %layout-#{$prop}s,
  .layout-#{$prop}s {
    #{$prop}-left: variables.$layout-app-content-padding-x-mobile;
    #{$prop}-right: variables.$layout-app-content-padding-x-mobile;

    @include mixins.for-tablet-portrait-up {
      #{$prop}-left: variables.$layout-app-content-padding-x-tablet;
      #{$prop}-right: variables.$layout-app-content-padding-x-tablet;
    }

    @include mixins.for-desktop-up {
      #{$prop}-left: variables.$layout-app-content-padding-x-desktop;
      #{$prop}-right: variables.$layout-app-content-padding-x-desktop;
    }

    @include mixins.for-desktop-wide-up {
      #{$prop}-left: variables.$layout-app-content-padding-x-desktop-wide;
      #{$prop}-right: variables.$layout-app-content-padding-x-desktop-wide;
    }
  }
}

@each $prop in (margin, padding) {
  %layout-top-#{$prop}s,
  .layout-top-#{$prop}s {
    #{$prop}-top: variables.$layout-app-content-padding-top-mobile;

    @include mixins.for-tablet-portrait-up {
      #{$prop}-top: variables.$layout-app-content-padding-top-tablet;
    }

    @include mixins.for-desktop-up {
      #{$prop}-top: variables.$layout-app-content-padding-top-desktop;
    }

    @include mixins.for-desktop-wide-up {
      #{$prop}-top: variables.$layout-app-content-padding-top-desktop-wide;
    }
  }
}

.layout-margin-padding {
  padding: variables.$layout-padding-x variables.$layout-app-content-padding-x-mobile;

  @include mixins.for-tablet-portrait-up {
    padding: variables.$layout-padding-x (variables.$layout-app-content-padding-x-tablet * 0.5);
    margin: 0 (variables.$layout-app-content-padding-x-tablet * 0.5);
  }

  @include mixins.for-desktop-up {
    padding: variables.$layout-padding-x (variables.$layout-app-content-padding-x-desktop * 0.5);
    margin: 0 (variables.$layout-app-content-padding-x-desktop * 0.5);
  }

  @include mixins.for-desktop-wide-up {
    padding: variables.$layout-padding-x
      (variables.$layout-app-content-padding-x-desktop-wide * 0.5);
    margin: 0 (variables.$layout-app-content-padding-x-desktop-wide * 0.5);
  }
}

$zendesk-internal-ats-bottom: 85px;
$zendesk-widget-height: 48px;

// zendesk
@include mixins.for-mobile-only {
  // move the launcher above the action-bar for internal-ats
  iframe#launcher,
  .zendesk-placeholder {
    &.internal-ats {
      bottom: $zendesk-internal-ats-bottom !important;
    }
  }

  // same thing but for the notification badge
  iframe#launcher {
    &.internal-ats {
      & ~ iframe {
        bottom: $zendesk-internal-ats-bottom + $zendesk-widget-height !important;
      }
    }
  }
}
