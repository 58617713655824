@use 'sass:map';
@use 'sass:math';
@use 'styles/functions';
@use 'styles/variables';
@use 'styles/colors';

@mixin flex($direction: row, $align: center, $justify: normal) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin grid($flow: column, $align: center, $justify: normal) {
  display: grid;
  grid-auto-flow: $flow;
  align-items: $align;
  justify-content: $justify;
}

@mixin button-colors($colors) {
  @each $color, $property in $colors {
    &-#{$color}.mdc-button {
      color: map.get($property, color);
      background-color: map.get($property, background-color);

      &:not(.button-color-link) {
        border: 1px solid map.get($property, border-color);
      }

      &:not(.mdc-button):not(:disabled):hover {
        background-color: map.get($property, hover-background-color);
        color: map.get($property, hover-color);

        &:not(.button-color-link) {
          border: 1px solid map.get($property, hover-border-color);
        }

        ::ng-deep svg path[fill='currentColor'] {
          fill: map.get($property, hover-color);
        }
      }

      &:not(.mdc-button):not(:disabled):active {
        background-color: map.get($property, active-background-color);
        color: map.get($property, active-color);

        &:not(.button-color-link) {
          border: 1px solid map.get($property, active-border-color);
        }

        ::ng-deep svg path[fill='currentColor'] {
          fill: map.get($property, active-color);
        }
      }

      &.mdc-button:disabled {
        background-color: map.get($property, disabled);
        color: map.get($property, disabled-color);
        border: 1px solid map.get($property, disabled);

        ::ng-deep svg path[fill='currentColor'] {
          fill: map.get($property, disabled-color);
        }
      }

      &:not(.mdc-button):not(:disabled) {
        ::ng-deep svg path[fill='currentColor'] {
          fill: map.get($property, color);
        }
      }

      &.with-dark-border {
        border-color: colors.$color-neutral-900;
        border-width: 2px;
      }
    }
  }
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin multi-line-ellipsis($line: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin grid-area($areas) {
  @each $area in $areas {
    @at-root .#{$area} {
      grid-area: $area;
    }
  }
}

@mixin for-mobile-only {
  @media only screen and (max-width: variables.$media-breakpoint-mobile-max) {
    @content;
  }
}

@mixin for-tablet-only {
  @media only screen and (min-width: variables.$media-breakpoint-tablet-min) and (max-width: variables.$media-breakpoint-tablet-max) {
    @content;
  }
}

@mixin for-tablet-portrait-only {
  @media only screen and (min-width: variables.$media-breakpoint-tablet-min) and (max-width: variables.$media-breakpoint-tablet-max) and (orientation: portrait) {
    @content;
  }
}

@mixin for-tablet-landscape-only {
  @media only screen and (min-width: variables.$media-breakpoint-tablet-min) and (max-width: variables.$media-breakpoint-tablet-max) and (orientation: landscape) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media only screen and (min-width: variables.$media-breakpoint-tablet-min) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media only screen and (min-width: variables.$media-breakpoint-tablet-portrait-max) {
    @content;
  }
}

@mixin for-desktop-up {
  @media only screen and (min-width: variables.$media-breakpoint-desktop-min), print {
    @content;
  }
}

@mixin for-desktop-wide-up {
  @media only screen and (min-width: variables.$media-breakpoint-desktop-wide-min), print {
    @content;
  }
}

@mixin for-desktop-ultra-wide-up {
  @media only screen and (min-width: variables.$media-breakpoint-desktop-ultra-wide-min), print {
    @content;
  }
}

@mixin for-tablet-down {
  @media only screen and (max-width: variables.$media-breakpoint-tablet-max), print {
    @content;
  }
}

@mixin typo($fw, $fs, $lh) {
  font-weight: $fw;
  font-size: functions.rem-calc($fs);
  line-height: functions.rem-calc($lh);
}

@mixin full-hw() {
  height: 100%;
  width: 100%;
}

@mixin reset-appearance() {
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
}

@mixin circle($size: 16px) {
  width: $size;
  height: $size;
  border-radius: math.div($size, 2);
}

@mixin absolute($top: auto, $right: auto, $bottom: auto, $left: auto) {
  position: absolute;
  inset: $top $right $bottom $left;
}

@mixin font-size($pxSize) {
  font-size: math.div($pxSize, 16) * 1rem !important;
}

@mixin font-weight($weight) {
  font-weight: $weight;
}

@mixin create-classlist($screen) {
  @for $i from 0 through 12 {
    .cute-#{$i}-#{$screen} {
      width: map.get(variables.$cute, $i);
    }

    .cute-#{$i}-#{$screen}-offset {
      margin: map.get(variables.$cute, $i);
    }

    .cute-#{$i}-#{$screen}-push {
      left: map.get(variables.$cute, $i);
    }

    .cute-#{$i}-#{$screen}-pull {
      right: map.get(variables.$cute, $i);
    }
  }
}

@mixin hide-scrollbar() {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  &::-webkit-scrollbar {
    display: none; // Hide scrollbar for IE, Edge and Firefox
  }
}

@mixin top-header-button-hover {
  transition: all 0.2s;

  &:hover {
    background-color: variables.$top-header-button-hover-color;
  }
}

@mixin fixed-bottom-status-bar($z-index, $padding, $background-color) {
  z-index: $z-index;
  padding: $padding;
  background-color: $background-color;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

// TODO: should this be only for ats or default UI for all modals ?
@mixin ats-modal() {
  ui-feedback-header {
    @include typo(700, 20px, 24px);

    padding-bottom: 0.5rem;
  }

  .content {
    @include typo(400, 16px, 24px);

    color: colors.$color-neutral-800;
    padding-top: 1rem;
    padding-bottom: 0.75rem;
  }

  ui-feedback-footer {
    padding-top: 1.5rem;
  }
}
