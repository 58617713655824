/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

.mat-mdc-list-base .mat-mdc-list-item {
  height: 85px !important;
}

app-upgrade-products .mat-mdc-list-base .mat-mdc-list-item {
  height: 85px !important;
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  .mat-list-item-content {
    padding: 0 !important;
  }
}
