/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

.mat-mdc-progress-bar {
  &.bg-white {
    height: 1px;
    /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
    .mat-progress-bar-buffer {
      background: white;
    }
  }
}
