@use 'styles/colors';
@use 'styles/mixins';
@use 'styles/grid';

$skeleton-item-border-color: colors.$color-neutral-200;
$skeleton-item-hover-blur: 8px;
$skeleton-item-hover-color: colors.$color-neutral-200;
$skeleton-header-margin-top: 16px;
$skeleton-header-border-bottom-color: colors.$color-neutral-100;

.skeleton-item {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  align-items: center;
  column-gap: 24px;
  cursor: pointer;
  transition: width ease-in-out 0.6s;
  margin-top: $skeleton-header-margin-top;

  &:hover {
    box-shadow: 0 0 $skeleton-item-hover-blur $skeleton-item-hover-color;
  }

  @include mixins.for-mobile-only {
    grid-template-columns: auto;
  }
}

.skeleton-column {
  @each $span in (1, 2, 3, 4, 5, 6, 7) {
    @include mixins.for-tablet-portrait-only {
      &.tablet-portrait\:span-#{$span} {
        grid-column: span $span;
      }
    }

    @include mixins.for-tablet-landscape-only {
      &.tablet-landscape\:span-#{$span} {
        grid-column: span $span;
      }
    }

    @include mixins.for-desktop-up {
      &.desktop\:span-#{$span} {
        grid-column: span $span;
      }
    }
  }
}

.column-with-checkbox {
  @include mixins.grid;

  grid-template-columns: 40px 1fr;
}

.column-with-checkbox-and-avatar {
  @include mixins.grid;

  grid-template-columns: 40px 70px 1fr;
}

mkp-skeleton-loader {
  width: 70%;
}

.menu {
  justify-self: flex-end;
}
