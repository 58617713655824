/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/mixins';
@use 'styles/colors';
@use 'styles/variables';

$gray-primary: colors.$gray-primary;

.simple-dialog {
  position: fixed !important;
  max-width: none !important;
  max-height: 100vh;
  inset: 0;

  .mat-mdc-dialog-container {
    padding: 0;
  }

  .mat-mdc-dialog-title {
    @include mixins.flex($justify: space-between);

    h2 {
      font-weight: bold;
    }
  }

  .mat-mdc-dialog-content {
    flex: auto;
    max-height: none;
  }

  .mat-mdc-dialog-actions {
    flex-wrap: nowrap;

    button {
      text-transform: capitalize;
      font-weight: 600;

      @include mixins.ellipsis;

      &.cancel-btn {
        color: $gray-primary;
      }
    }
  }
}

@media (min-width: variables.$media-breakpoint-mobile-max) {
  .simple-dialog {
    position: static !important;
    max-width: 80vw;

    .mat-mdc-dialog-content {
      max-height: 65vh;
    }
  }
}

.mdc-dialog__title {
  &::before {
    height: 0 !important;
  }

  .mat-icon {
    margin: 0 !important;
  }
}
