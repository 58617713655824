/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';
@use 'styles/variables';

$purple: colors.$purple;

$icons: (
  sort: '\e910',
  arrow-left: '\e940',
  attach: '\e226',
  check_off: '\e90b',
  close: '\e5cd',
  copy: '\e14d',
  done: '\e876',
  error_outline: '\e90d',
  inbox_in: '\e159',
  left: '\e314',
  icon-down: '\e313',
);

.icon-disable {
  cursor: default;
  opacity: 0.1;
}

@font-face {
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-family: variables.$font-family;
  src: local(variables.$font-family);
}

[class^='icon-'],
[class*=' icon-']:not(.icon-only) {
  font-family: '#{variables.$font-family}' !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-style: initial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::ng-deep .ui-button-left {
  text-align: left !important;
}

.product-icon {
  background-repeat: no-repeat;
  background-size: 14px;
  width: 14px;
  height: 14px;
  display: inline-block;
}

@each $icon, $value in $icons {
  .icon-#{$icon}::before {
    content: $value;
  }
}
