/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';
@use  'styles/variables';

$white: colors.$white;
$gray-darken: colors.$gray-darken;

.demo-full-width {
  width: 100%;

  * {
    display: block;
    width: 100%;
  }
}

.overflow-hidden {
  overflow: hidden;
}

:focus {
  outline-color: transparent;
  outline-style: none;
}

.position-relative {
  position: relative;
}

/* link */
em {
  font-style: italic !important;
}

a,
.display-cursor {
  cursor: pointer;
}

/* alignment */
.align-right {
  text-align: right !important;
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.valign-top {
  vertical-align: top;
}

/* text */
.text-bold {
  font-weight: bold;
}

img {
  vertical-align: middle;
  border-style: none;
}

/* display */
.hidden-form-field {
  position: fixed !important;
  opacity: 0 !important;
  z-index: -1 !important;
}

.word-break {
  word-break: break-word;
}

.text-ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-word;
  text-overflow: ellipsis;
  max-width: 240px; // updatef from 300 to 240 for mobile
  &::after {
    content: '...';
  }
}

@media screen and (min-width: variables.$media-breakpoint-mobile) {
  .text-ellipsis {
    display: block;
    overflow: initial;
    white-space: normal;
    text-overflow: initial;
    max-width: 100%;
  }
}

.noScroll {
  appearance: none;
  overflow: hidden !important;
}

.frameScroll::-webkit-scrollbar {
  appearance: none;
}

.frameScroll::-webkit-scrollbar-thumb {
  border-radius: variables.$size-xs;
  border: 3px solid $white;
  background-color: $gray-darken;
}

.frameScroll::-webkit-scrollbar-track {
  background-color: $white;
  border-radius: variables.$size-xs;
}

.frameScroll::-webkit-scrollbar:vertical {
  width: variables.$size-s;
}

.frameScroll::-webkit-scrollbar:horizontal {
  height: variables.$size-s;
}
