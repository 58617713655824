@use 'styles/colors';
@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/typography';
@use 'sass:math';

$table-row-radius: 0.25rem;
$table-row-active-background-color: colors.$color-neutral-50;
$header-cell-color: colors.$color-neutral-800;
$table-row-padding-y-mobile: 1rem;
$table-row-padding-y: 1.5rem;
$table-header-padding-y: 1rem;
$table-row-padding-x-mobile: 1.5rem;
$table-row-padding-x-tablet: 1rem;
$table-row-padding-x-desktop: 1.5rem;
$table-row-padding-x-desktop-wide: 2rem;
$table-row-hover-blur: 0.5rem;
$mobile-table-header-border-color: colors.$color-neutral-100;
$mobile-first-cdk-row-margin-top: 1rem;
$table-header-row-border-bottom-color: colors.$color-neutral-100;
$table-header-row-border-bottom-width: 1px;
$new-rows-text-background-color: colors.$color-neutral-200;
$new-rows-text-color: colors.$color-neutral-900;
$new-rows-text-border-radius: 1.25rem;
$new-rows-text-padding-x: 0.125rem;
$new-rows-text-padding-y: 1rem;
$new-rows-text-width: 18.75rem;

.cdk-table {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  background-color: variables.$body-background-color;
  row-gap: 1rem;
  padding: 0 variables.$layout-app-content-padding-x-mobile;

  @include mixins.for-tablet-portrait-up {
    padding: 0 variables.$layout-app-content-padding-x-tablet;
  }

  @include mixins.for-desktop-up {
    padding: 0 variables.$layout-app-content-padding-x-desktop;
    align-items: center;
  }

  @include mixins.for-desktop-wide-up {
    padding: 0 variables.$layout-app-content-padding-x-desktop-wide;
    align-items: center;
  }
}

.cdk-row,
.cdk-header-row {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  align-items: center;
  column-gap: 1.5rem;
}

.cdk-row {
  background: colors.$white;
  border-radius: $table-row-radius;
  padding: $table-row-padding-y-mobile $table-row-padding-x-tablet;

  @include mixins.for-tablet-portrait-up {
    padding: $table-row-padding-y $table-row-padding-x-tablet;
  }

  // padding: $table-row-padding-y $table-row-padding-x-mobile;

  // @include mixins.for-tablet-portrait-up {
  // padding: $table-row-padding-y $table-row-padding-x-tablet;
  // }

  // @include mixins.for-desktop-up {
  //   padding: $table-row-padding-y $table-row-padding-x-desktop;
  //   align-items: center;
  // }

  // @include mixins.for-desktop-wide-up {
  //   padding: $table-row-padding-y $table-row-padding-x-desktop-wide;
  //   align-items: center;
  // }
}

.cdk-header-row {
  padding: $table-header-padding-y 0;
  border-bottom: $table-header-row-border-bottom-width solid $table-header-row-border-bottom-color;

  // @include mixins.for-mobile-only {
  //   padding: $table-row-padding-x variables.$layout-app-content-padding-x-mobile;
  // }

  // @include mixins.for-tablet-portrait-up {
  //   margin: 0 variables.$layout-app-content-padding-x-tablet;
  // }

  // @include mixins.for-desktop-up {
  //   margin: 0 variables.$layout-app-content-padding-x-desktop;
  // }

  // @include mixins.for-desktop-wide-up {
  //   margin: 0 variables.$layout-app-content-padding-x-desktop-wide;
  // }
}

.cdk-cell,
.cdk-header-cell {
  overflow: hidden;
}

@each $span in (1, 2, 3, 4, 5, 6, 7) {
  @include mixins.for-tablet-portrait-only {
    .tablet-portrait\:span-#{$span} {
      grid-column: span $span;
    }
  }

  @include mixins.for-tablet-landscape-only {
    .tablet-landscape\:span-#{$span} {
      grid-column: span $span;
    }
  }

  @include mixins.for-tablet-portrait-up {
    .tablet\:span-#{$span} {
      grid-column: span $span;
    }
  }

  @include mixins.for-desktop-up {
    .desktop\:span-#{$span} {
      grid-column: span $span;
    }
  }
}

.cdk-header-cell,
.cdk-header-title {
  @include mixins.ellipsis;

  color: $header-cell-color;

  @extend %body-s;

  // @extend %semibold;
}

.cdk-row:hover {
  box-shadow: 0 0 $table-row-hover-blur colors.$color-neutral-200;
  cursor: pointer;
  background-color: white;
}

.cdk-row.active {
  background-color: $table-row-active-background-color;
}

// Margin on the first .cdk-row on the mobile, nth-child(1) is .cdk-header-row
@include mixins.for-mobile-only {
  .cdk-row:first-of-type {
    margin-top: $mobile-first-cdk-row-margin-top;
  }
}

// Load More - new rows
.new-rows {
  border-top: 2px solid colors.$color-neutral-200;
  position: relative;
  padding-top: 2 * $table-row-padding-y;
  margin-top: 2 * $table-row-padding-y;
}

.new-rows-text {
  position: absolute;
  top: -15px;
  left: calc(50% - (#{$new-rows-text-width} / 2));
  width: $new-rows-text-width; // TODO
  animation: new-rows-text 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  @include mixins.flex(row, center, center);

  span {
    background-color: $new-rows-text-background-color;
    color: $new-rows-text-color;
    border-radius: $new-rows-text-border-radius;
    padding: $new-rows-text-padding-x $new-rows-text-padding-y;
  }
}

@keyframes new-rows-text {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}
