/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';
@use 'styles/variables';
@use 'styles/functions';

$gray-primary: colors.$gray-primary;

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-arrow {
  font-style: normal;
  width: 25px !important;
  height: 25px !important;
  border-left: 0 solid transparent !important;
  border-right: 0 solid transparent !important;
  border-top: 0 solid !important;
  margin: -6px 4px !important;
  color: $gray-primary;

  &::after {
    position: absolute;
    font-family: marketplace, serif !important;
    content: '\e313';
    font-size: 1.8em;
    right: 0;
  }
}

$select-focused-border-size: functions.rem-calc(2px);
$select-x-padding: functions.rem-calc(14px);
$select-y-padding: functions.rem-calc(11px);

// for some reason the position of the panel of the select-component needs to be adjusted slightly
.cdk-overlay-container {
  .select-custom {
    &.mat-mdc-select-panel {
      margin-top: $select-y-padding;
      margin-left: calc(-#{$select-focused-border-size} - #{$select-x-padding});
      min-width: calc(variables.$select-min-width + #{$select-x-padding});
    }
  }
}
