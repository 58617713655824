/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';
@use 'styles/variables';

$spinner-color: colors.$color-neutral-300 colors.$color-neutral-300 colors.$color-neutral-600
  colors.$color-neutral-600;
$loading-color: colors.$color-neutral-50;

// splashscreen related
body .mkp-loading {
  display: none;
}

body.splashscreen .mkp-loading {
  background: $loading-color;
  position: absolute;
  display: flex; // no important please
  justify-content: center;
  height: 100vh;
  width: 100vw;
  inset: 0;
  align-items: center;
  z-index: variables.$z-index-max;
}

/* spinner style */
.spinner-container {
  position: absolute;
  width: 80px;
  height: 80px;
  animation: fadeIn 0.75s ease-in-out;
}

.spinner {
  width: 80px;
  height: 80px;
  border: 2px solid;
  border-color: $spinner-color;
  opacity: 0.9;
  border-radius: 100px;
  animation: rotate 1s linear infinite;

  &.spinner-small {
    width: 20px;
    height: 20px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: variables.$media-breakpoint-mobile) {
  .spinner-container {
    width: 80px !important;
  }
}
