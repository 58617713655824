/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';
@use '@angular/material' as mat;

$mat-menu-content-padding-y: 8px;
$mat-menu-content-padding-x: 0;

.mat-mdc-menu-panel {
  border-radius: 0 !important;
  margin-top: 15px;

  &.mat-elevation-z8 {
    @include mat.elevation(3);
  }

  .mat-mdc-menu-content {
    padding: $mat-menu-content-padding-y $mat-menu-content-padding-x;
    width: 100%;

    hr {
      border: unset;
      border-bottom: 1px inset colors.$color-neutral-200;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version. */
.mat-menu-select {
  background: rgb(0 0 0 / 4%) !important;
  border-radius: 7px !important;
}
