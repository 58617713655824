@use 'styles/mixins';

$font-stack: unquote("Open Sans"), unquote("Helvetica Neue"), helvetica, arial, sans-serif;

$titles: (
  "xxl": (800, 48, 58),
  "xl": (800, 37, 44),
  "l": (700, 32, 38),
  "m": (700, 28, 34),
  "s": (700, 24, 29),
  "xs": (700, 20, 24),
  "xxs": (700, 16, 19),
  "lead": (700, 12, 14)
);

$bodies: (
  "default": (400, 16, 24),
  "s": (400, 14, 21),
  "xs": (400, 12, 18)
);

@each $title, $value in $titles {
  .title-#{$title},
  %title-#{$title} {
    @include mixins.typo(nth($value, 1), nth($value, 2), nth($value, 3));
  }
}

%body-default, %body-s, %body-xs {
  /* for autocompletion */
}

@each $body, $value in $bodies {
  .body-#{$body},
  %body-#{$body} {
    @include mixins.typo(nth($value, 1), nth($value, 2), nth($value, 3));
  }
}

.nobold, %nobold {
  font-weight: 400;
}

.semibold, %semibold {
  font-weight: 600;
}

.bold, %bold {
  font-weight: 700;
}

.italic, %italic {
  font-style: italic;
}

h1 {
  @include mixins.typo(700, 28, 34);
}

h2 {
  @include mixins.typo(700, 20, 24);
}

h3 {
  @include mixins.typo(700, 16, 19);
}

@include mixins.for-mobile-only {
  h1 {
    @include mixins.typo(700, 22, 26);
  }
}
