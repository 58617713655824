/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

$host-min-height: 80px;
$spinner-small-top: 35px;
$spinner-small-left: 15px;

.page-header {
  width: 100%;
  left: 0;
  display: block;
  z-index: -1;

  .spinner-small {
    top: $spinner-small-top;
    left: $spinner-small-left;
  }
}
