/**
* base styles
*/
@import 'palette';
@import 'text';
@import 'utils';
@import 'layout';
@import 'cutegrids';
@import 'margin-padding';
@import 'paragraphs';

/**
* other styles
*/
@import 'marketplace';

/**
* redesign
*/
@import 'styles/helpers';
@import 'styles/fonts';
@import 'styles/typography';
@import 'styles/material';
@import 'styles/animations';
@import 'styles/table';
@import 'styles/print';
@import 'styles/skeleton';
@import 'styles/platforms';

/**
* @REVIEW
* component styles
*/
@import 'components/auth';
@import 'components/avatar';
@import 'components/buttons';
@import 'components/button-indicator';
@import 'components/ghost';
@import 'components/list';
@import 'components/form';
@import 'components/mat-card';
@import 'components/mat-menu-panel';
@import 'components/mat-chip';
@import 'components/mat-progress-bar';
@import 'components/mat-list-item';
@import 'components/mat-select';
@import 'components/mat-snack-bar';
@import 'components/mat-tab';
@import 'components/message-panel';
@import 'components/modal';
@import 'components/mk-panel';
@import 'components/page-header';
@import 'components/spinner';
@import 'components/text-editor';
@import 'components/mat-dialog';
@import 'components/tooltip';

/**
* MDC MIGRATION
*/
@import 'styles/theme';
