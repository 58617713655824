// Jobs Brand Colors
$color-jobs-brand-01: #F5FBFE;
$color-jobs-brand-10: #C3E1FA;
$color-jobs-brand-20: #90C7F6;
$color-jobs-brand-30: #55ABF1;
$color-jobs-brand-40: #284EE2;
$color-jobs-brand-50: #085DBF;
$color-jobs-brand-60-base: #004099;
$color-jobs-brand-70: #032E83;
$color-jobs-brand-80: #021467;
$color-jobs-brand-90: #000B48;

// JobUp Brand Colors
$color-jobup-brand-01: #F3F9EB;
$color-jobup-brand-10: #DFEECA;
$color-jobup-brand-20: #BCDC90;
$color-jobup-brand-30: #95C651;
$color-jobup-brand-40: #70A526;
$color-jobup-brand-50-base: #4B820D;
$color-jobup-brand-60: #3E6A0E;
$color-jobup-brand-70: #30510D;
$color-jobup-brand-80: #22380A;
$color-jobup-brand-90: #152602;

// JobScout24 Brand Colors
$color-jobscout24-brand-30: #FF9722;
$color-jobscout24-brand-60-base: #FF7500;
$color-jobscout24-brand-70: #ba5601;
$color-jobscout24-brand-80: #743600;
