@use 'styles/variables';

/**
 * here's print style for marketplace
 */
@media print {
  @page {
    size: a3;
  }

  // LAYOUT
  ui-navigation,
  ui-top-header,
  footer div:not(.copyright) {
    display: none !important;
  }

  html {
    font-size: 14pt;
  }

  body {
    background: white !important;
  }

  .layout-margins,
  .layout-padding,
  footer {
    padding: 0 !important;
    margin: 0 !important;
  }

  // CDK TABLE
  .cdk-column-download {
    display: none !important;
  }

  .cdk-column-name.desktop\:span-5 {
    grid-column: span 4 !important;
  }

  .cdk-column-status.desktop\:span-2,
  .cdk-column-price.desktop\:span-2 {
    grid-column: span 1 !important;
  }

  .cdk-row,
  .cdk-header-row {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
