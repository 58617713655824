/* TYPOGRAPHY */

/* Import Open Sauce Two font  */

@font-face {
  font-family: 'Open Sauce Two';
  font-display: swap;
  ascent-override: 100%;
  descent-override: 20%;
  src:
    url('/assets/fonts/OpenSauceTwo-Regular-subset.woff2') format('woff2'),
    url('/assets/fonts/OpenSauceTwo-Regular-subset.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce Two';
  font-weight: 600;
  font-display: swap;
  ascent-override: 100%;
  descent-override: 20%;
  src:
    url('/assets/fonts/OpenSauceTwo-SemiBold-subset.woff2') format('woff2'),
    url('/assets/fonts/OpenSauceTwo-SemiBold-subset.woff') format('woff');
}

@font-face {
  font-family: 'Open Sauce Two';
  font-weight: 700;
  font-display: swap;
  ascent-override: 100%;
  descent-override: 20%;
  src:
    url('/assets/fonts/OpenSauceTwo-Bold-subset.woff2') format('woff2'),
    url('/assets/fonts/OpenSauceTwo-Bold-subset.woff') format('woff');
}
