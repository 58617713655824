/**
 * if you need paddings or margins, please use
 * .layout-paddings or .layout-margins.
 */
@mixin init-grid($cols: 12, $template: repeat($cols, 1fr), $gap: 1.5rem) {
  display: grid;
  column-gap: $gap;
  grid-template-columns: $template;
}

@mixin grid-area($areas, $template-areas) {
  @if $template-areas {
    grid-template-areas: $template-areas;
  }

  @each $area in $areas {
    @at-root {
      .#{$area} {
        grid-area: $area;
      }
    }
  }
}
