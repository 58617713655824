@use 'sass:math';
@use 'sass:color';
@use 'styles/colors';

/**
* breakpoints
*/
$media-breakpoint-mobile-min: 319px;
$media-breakpoint-mobile-max: 767px;
$media-breakpoint-mobile-landscape-max: 812px;
$media-breakpoint-tablet-min: 768px;
$media-breakpoint-tablet-portrait-max: 1024px;
$media-breakpoint-tablet-max: 1199px;
$media-breakpoint-desktop-min: 1200px;
$media-breakpoint-desktop-max: 1439px;
$media-breakpoint-desktop-wide-min: 1440px;
$media-breakpoint-desktop-wide-max: 1899px;
$media-breakpoint-desktop-ultra-wide-min: 1900px;
$media-breakpoint-desktop-ultra-wide-max: unset;

/**
* badge
*/
$badge-small-size: 8px;

/**
* z-index
*/
$z-index-button: 1;
$z-index-product-offering-header: $z-index-button;
$z-index-ats-details-mobile-action-bar: $z-index-button + 1;
$z-index-top-header: 10003;
$z-index-loading: $z-index-top-header;
$z-index-navigation: $z-index-top-header + 1;
$z-index-navigation-backdrop: $z-index-navigation - 1;
$z-index-cdk-overlay: $z-index-navigation + 1;
$z-index-typeahead: $z-index-top-header;
$z-index-drawer: $z-index-top-header + 1;
$z-index-drawer-backdrop: $z-index-top-header;
$z-index-snackbar: $z-index-drawer + 1;

/**
* product-offering
*/
$product-grid-gap: 1rem;
$product-width: 13.625rem;
$product-row-header-width: 19rem;
$product-section-width: $product-row-header-width + ($product-width + $product-grid-gap) * 4;
$product-section-min-width: $product-row-header-width + ($product-width + $product-grid-gap) * 3;
$product-scroll-width: 9rem;
$row-header-width: 19rem;

/**
* TOP HEADER
*/

$body-background-color: colors.$color-neutral-50;
$footer-margin-top: 25px;
$skp-scroll-shadow-color: rgb(24 39 75 / 12%);
$top-header-desktop-background-color: colors.$color-neutral-800;
$top-header-height: 64px;
$top-header-mobile-background-color: colors.$color-neutral-800;
$top-header-padding-bottom: 4px;
$top-header-padding-left: 24px;
$top-header-padding-right-wide: 0.5rem;
$top-header-padding-right: 16px;
$top-header-padding-top: 4px;
$top-header-presenter-desktop-background-color: colors.$color-neutral-800;
$top-header-presenter-height: 64px;
$top-header-presenter-mobile-background-color: colors.$color-neutral-800;
$top-header-presenter-padding-bottom: 4px;
$top-header-presenter-padding-left: 24px;
$top-header-presenter-padding-right-wide: 24px;
$top-header-presenter-padding-right: 16px;
$top-header-presenter-padding-top: 4px;
$top-header-button-hover-color: color.adjust($top-header-desktop-background-color, $lightness: 5%);

/**
* ATS
*/
$ats-background-colors: (
  colors.$color-brand-purple-500,
  colors.$color-brand-turquoise-500,
  colors.$color-brand-blue-500,
  colors.$color-neutral-800,
  colors.$color-neutral-100
);
$ats-colors: (white, white, white, white, colors.$color-neutral-600);
$ats-mobile-action-bar-height: 5rem;
$ats-list-padding-vertical: 0.5rem;
$ats-avatar-medium-size: 40px;

///////////////////////////////

/**
* general
*/
$global-font-size: 14px;
$global-lineheight: 1.5rem;
$body-background: rgb(255 255 255);
$border-bottom: 1px solid rgb(0 0 0 / 12%);
$header-height: 50px;
$footer-margin-top: 25px;
$footer-height: 70px;
$footer-height-ios: 180px;
$footer-height-ios-notch: 230px;
$border-radius: 4px;
$box-shadow:
  0 1px 1px 0 rgb(0 0 0 / 14%),
  0 2px 1px -1px rgb(0 0 0 / 12%),
  0 1px 3px 0 rgb(0 0 0 / 20%);
$border-size: 2px;
$sub-header-height: 130px;
$progressbar-height: 4px;
$logo-splashscreen-size: 10rem;
$box-default-radius: 8px;
$box-button-radius: 24px;
$page-height: calc(100% - #{$footer-height + $footer-margin-top + $header-height});
$icon-dot-size: 8px;

/**
* rem references
*/
$tiny-rem: 0.1rem; // 1.6px
$smallest-rem: 0.25rem; // 4px
$smaller-rem: 0.5rem; // 8px
$smallish-rem: 0.63rem; // 10px
$small-rem: 0.75rem; // 12px
$regular-rem: 0.875rem; // 14px;
$normal-rem: 1rem; // 16px
$mid-rem: 1.25rem; // 20px
$medium-rem: 1.5rem; // 24px
$large-rem: 2rem; // 32px
$larger-rem: 3rem; // 48px
$largest-rem: 4rem; // 64px
$huge-rem: 5rem;

// use this function when the UI reference is given in pixel for spacings
@function rem-calc($px) {
  @return math.div(math.div($px, 16), 1px) * 1rem;
}

/**
* size ?
*/
$size-0: 0;
$size-xxs: 4px;
$size-xs: 8px;
$size-s: 12px;
$size-m: 16px;
$size-l: 20px;
$size-xl: 24px;
$size-xxl: 28px;
$size-xxxl: 32px;
$margin-top-wrapper: 40px;

/**
* marketplace margins
*/
$margin-0: $size-0;
$margin-xxs: $size-xxs;
$margin-xs: $size-xs;
$margin-s: $size-s;
$margin-m: $size-m;
$margin-l: $size-l;
$margin-xl: $size-xl;
$margin-xxl: $size-xxl;
$margin-xxxl: $size-xxxl;

/**
* marketplace padding
*/
$padding-0: $size-0;
$padding-xxs: $size-xxs;
$padding-xs: $size-xs;
$padding-s: $size-s;
$padding-m: $size-m;
$padding-l: $size-l;
$padding-xl: $size-xl;
$padding-xxl: $size-xxl;
$padding-xxxl: $size-xxxl;

/**
* grid
*/
$grid-columns: 12;
$grid-max-width: 1200px;
$grid-margin: $margin-m;
$grid-margin-bigger: $margin-xxxl !important;
$grid-margin-forms: $margin-xxs $margin-xs !important;

/**
* responsive design
*/
$gutter-desktop: $margin-xxxl;
$gutter-mobile: $margin-m;

/**
* breakpoints
*/
$media-breakpoint-mobile: 480px;
$media-breakpoint-tablet-min: 481px;
$media-breakpoint-tablet: 768px;
$media-breakpoint-desktop-min: 769px;
$media-breakpoint-desktop: 992px;
$media-breakpoint-wide-desktop-min: 993px;
$media-breakpoint-wide-desktop: 1200px;
$media-breakpoint-wide-extra-desktop: 1600px;

/**
* cute grids
*/
$cute: (
  0: 0,
  1: 8.3333%,
  2: 16.6667%,
  3: 25%,
  4: 33.3333%,
  5: 41.6667%,
  6: 50%,
  7: 58.3333%,
  8: 66.6667%,
  9: 75%,
  10: 83.3333%,
  11: 91.6667%,
  12: 100%,
);
$border-bottom: 1px solid colors.$shade-light; // rgba(0, 0, 0, 0.12);

/**
* marketplace custom font from icomoon
*/
$font-family: 'marketplace' !default;
$font-path: '/assets/fonts' !default;
$full-width: 100%;

/**
* z-index
*/
$z-index: 9999;
$z-index-1: 1;
$z-index-loading: 99999;
$z-index-max: 2147483002;
$z-index-negative: -1;

// REDESIGN
$z-index-navigation: 10001;
$z-index-navigation-backdrop: $z-index-navigation - 1;

/////////////////////////////////

/**
* breakpoints
*/
$media-breakpoint-mobile-min: 319px;
$media-breakpoint-mobile-max: 767px;
$media-breakpoint-mobile-landscape-max: 812px;
$media-breakpoint-tablet-min: 768px;
$media-breakpoint-tablet-portrait-max: 1024px;
$media-breakpoint-tablet-max: 1199px;
$media-breakpoint-desktop-min: 1200px;
$media-breakpoint-desktop-max: 1439px;
$media-breakpoint-desktop-wide-min: 1440px;
$media-breakpoint-desktop-wide-max: 1899px;
$media-breakpoint-desktop-ultra-wide-min: 1900px;
$media-breakpoint-desktop-ultra-wide-max: unset;

/**
* layout
*/
$layout-padding-x: 16px;
$layout-app-content-padding-x-mobile: 24px;
$layout-app-content-padding-x-tablet: 32px;
$layout-app-content-padding-x-desktop: 48px;
$layout-app-content-padding-x-desktop-wide: 64px;

/**
* layout top
*/
$layout-padding-top: 16px;
$layout-app-content-padding-top-mobile: 16px;
$layout-app-content-padding-top-tablet: 24px;
$layout-app-content-padding-top-desktop: 32px;
$layout-app-content-padding-top-desktop-wide: 32px;

/**
 * navigation
 */
$navigation-body-background-color: colors.$color-neutral-800;
$navigation-body-padding: 8px;
$navigation-items-in-between-margin: 24px;
$navigation-width-desktop-expanded: 270px;
$navigation-width-desktop-collapsed: 64px;

/**
* badge
*/
$badge-small-size: 8px;

/**
* product-offering
*/
$product-grid-gap: 1rem;
$product-width: 13.625rem;
$product-row-header-width: 19rem;
$product-section-width: $product-row-header-width + ($product-width + $product-grid-gap) * 4;
$product-section-min-width: $product-row-header-width + ($product-width + $product-grid-gap) * 3;
$product-scroll-width: 9rem;
$row-header-width: 19rem;

/**
* TYPO
*/
$body-default-line-height: 24px;
$body-s-line-height: 21px;
$body-xs-line-height: 18px;

/**
* FOOTER
*/
$footer-height: 95px;

/**
* GO-BACK BUTTON
*/
$go-back-button-height: 48px;

/**
* TAB-HEADER
*/
$tab-item-padding-bottom: 12px;
$tab-height-large: $body-default-line-height + $tab-item-padding-bottom;
$tab-height-small: $body-s-line-height + $tab-item-padding-bottom;

/**
* SELECT component
*/
$select-min-width: 3rem;

/*

WE DESIGN MOBILE FIRST

DEFAULT CSS IS FOR THE MOBILE
// .... {}

MEDIAS ARE FOR THE TABLET
// @media screen and (min-width: variables.$media-breakpoint-tablet-min) { ... }

THEN MEDIAS ARE FOR THE DESKTOP
// @media screen and (min-width: variables.$media-breakpoint-desktop-min) { ... }

===== VERY SPECIAL CASES =====
**should not be used on regular purposes**

ONLY STYLE TABLET TO UPDATE
// @media screen and (min-width: variables.$media-breakpoint-tablet-min)
   and (max-width: variables.$media-breakpoint-tablet-max) { ... }

ONLY STYLE DESKTOP TO UPDATE
// @media screen and (min-width: variables.$media-breakpoint-desktop-min)
   and (max-width: variables.$media-breakpoint-desktop-max) { ... }

IMPLEMENT MIXINS FROM THAT https://itnext.io/writing-media-queries-with-sass-mixins-3ea591ea3ea4

*/
