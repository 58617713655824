// Material animations:

// Manual calculation done on SVG
$path-length: 22.910259;

// Animation variables
$indeterminate-checked-easing-function: cubic-bezier(0.14, 0, 0, 1);
$linear-out-slow-in-timing-function: cubic-bezier(0, 0, 0.2, 0.1) !default;
$fast-out-linear-in-timing-function: cubic-bezier(0.4, 0, 1, 1) !default;
$transition-duration: 90ms;

// Fades in the background of the checkbox when it goes from unchecked -> {checked,indeterminate}.
@keyframes fade-in-background {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

// Fades out the background of the checkbox when it goes from {checked,indeterminate} -> unchecked.
@keyframes fade-out-background {
  0%,
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

// "Draws" in the checkmark when the checkbox goes from unchecked -> checked.
@keyframes checkbox-unchecked-checked-checkmark-path {
  0%,
  50% {
    stroke-dashoffset: $path-length;
  }

  50% {
    animation-timing-function: $linear-out-slow-in-timing-function;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

// Horizontally expands the mixedmark when the checkbox goes from unchecked -> indeterminate.
@keyframes checkbox-unchecked-indeterminate-mixedmark {
  0%,
  68.2% {
    transform: scaleX(0);
  }

  68.2% {
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }

  100% {
    transform: scaleX(1);
  }
}

// "Erases" the checkmark when the checkbox goes from checked -> unchecked.
@keyframes checkbox-checked-unchecked-checkmark-path {
  from {
    animation-timing-function: $fast-out-linear-in-timing-function;
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: $path-length * -1;
  }
}

// Rotates and fades out the checkmark when the checkbox goes from checked -> indeterminate. This
// animation helps provide the illusion of the checkmark "morphing" into the mixedmark.
@keyframes checkbox-checked-indeterminate-checkmark {
  from {
    animation-timing-function: $linear-out-slow-in-timing-function;
    opacity: 1;
    transform: rotate(0deg);
  }

  to {
    opacity: 0;
    transform: rotate(45deg);
  }
}

// Rotates and fades the checkmark back into position when the checkbox goes from indeterminate ->
// checked. This animation helps provide the illusion that the mixedmark is "morphing" into the
// checkmark.
@keyframes checkbox-indeterminate-checked-checkmark {
  from {
    animation-timing-function: $indeterminate-checked-easing-function;
    opacity: 0;
    transform: rotate(45deg);
  }

  to {
    opacity: 1;
    transform: rotate(360deg);
  }
}

// Rotates and fades in the mixedmark when the checkbox goes from checked -> indeterminate. This
// animation, similar to checkbox-checked-indeterminate-checkmark, helps provide an illusion
// of "morphing" from checkmark -> mixedmark.
@keyframes checkbox-checked-indeterminate-mixedmark {
  from {
    animation-timing-function: $linear-out-slow-in-timing-function;
    opacity: 0;
    transform: rotate(-45deg);
  }

  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}

// Rotates and fades out the mixedmark when the checkbox goes from indeterminate -> checked. This
// animation, similar to checkbox-indeterminate-checked-checkmark, helps provide an illusion
// of "morphing" from mixedmark -> checkmark.
@keyframes checkbox-indeterminate-checked-mixedmark {
  from {
    animation-timing-function: $indeterminate-checked-easing-function;
    opacity: 1;
    transform: rotate(0deg);
  }

  to {
    opacity: 0;
    transform: rotate(315deg);
  }
}

// Horizontally collapses and fades out the mixedmark when the checkbox goes from indeterminate ->
// unchecked.
@keyframes checkbox-indeterminate-unchecked-mixedmark {
  0% {
    animation-timing-function: linear;
    opacity: 1;
    transform: scaleX(1);
  }

  32.8%,
  100% {
    opacity: 0;
    transform: scaleX(0);
  }
}

.checkbox-wrapper {
  $indeterminate-change-duration: 500ms;

  .checkbox-background {
    animation: $transition-duration * 2 linear 0ms fade-in-background;
  }

  .checkbox-checkmark-path {
    animation: $transition-duration * 2 linear 0ms checkbox-unchecked-checked-checkmark-path;
  }

  &:not(.checkbox-checked) {
    .checkbox-background {
      animation: $transition-duration * 2 linear 0ms fade-out-background;
    }

    .checkbox-checkmark-path {
      animation: $transition-duration linear 0ms checkbox-checked-unchecked-checkmark-path;
    }
  }

  &.checkbox-indeterminate {
    .checkbox-background {
      animation: $transition-duration * 2 linear 0ms fade-in-background;
    }

    .checkbox-mixedmark {
      animation: $transition-duration linear 0ms checkbox-unchecked-indeterminate-mixedmark;
    }

    &:not(.checkbox-checked) {
      .checkbox-background {
        animation: $transition-duration * 2 linear 0ms fade-out-background;
      }

      .checkbox-mixedmark {
        animation: $indeterminate-change-duration * 0.6 linear 0ms
        checkbox-indeterminate-unchecked-mixedmark;
      }
    }
  }
}
