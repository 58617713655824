/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';
@use 'styles/variables';
@use 'styles/typography';

$white: colors.$white;
$primary--color: colors.$primary--color;
$purple: colors.$purple;
$green: colors.$green;

.auth-lock-widget {
  box-shadow: none;

  @media (min-width: variables.$media-breakpoint-mobile) {
    box-shadow: 0 0 40px 4px rgba($color: $primary--color, $alpha: 0%) !important;
  }
}

.auth0-lock-signup {
  font-size: 13px;
  color: colors.$black;
}

.auth0-lock-signup-link {
  color: $primary--color;
  cursor: pointer;
}

.auth0-lock-alternative-link {
  color: $primary--color !important;
}

.auth0-lock.auth0-lock {
  * {
    font-family: typography.$font-stack;
  }

  &.auth0-lock-opened .auth0-lock-widget {
    box-shadow: 0 0 40px 4px rgba($color: $primary--color, $alpha: 20%) !important;
  }

  .auth0-lock-header-welcome {
    align-self: center;
  }

  .auth0-lock-header-logo {
    display: block !important;
    height: 30px !important;
    margin: 5px 0 !important;
  }

  .auth0-lock-name {
    display: none !important;
  }

  .auth0-label-submit {
    font-size: 12px !important;
  }

  .auth0-lock-input-wrap {
    .auth0-lock-input {
      font-size: 14px !important;
    }
  }

  .auth0-lock-input-show-password {
    .auth0-lock-input-wrap {
      .auth0-lock-input {
        padding: 0 35px 0 14px !important;
      }
    }
  }

  .auth0-lock-header {
    @media (min-width: variables.$media-breakpoint-mobile) {
      background: $white !important;
      padding: 0 !important;
      margin-bottom: 45px !important;
      height: 0 !important;
    }

    @media (max-width: variables.$media-breakpoint-mobile) {
      background: $white !important;
    }
  }

  .auth0-lock-overlay {
    background: $white !important;
    opacity: 0.7 !important;
  }

  .auth0-lock-social-big-button {
    background-color: $primary--color !important;
  }

  .auth0-lock-header-bg-solid {
    opacity: 1 !important;
  }

  .auth0-lock-submit {
    height: 70px !important;
    background: $primary--color !important;
  }

  .auth0-lock-badge-bottom {
    display: none !important;
  }

  .auth0-global-message {
    &.auth0-global-message-error {
      background: $purple !important;
    }

    &.auth0-global-message-success {
      background: $green !important;
    }

    .auth0-lock.auth0-lock .auth0-lock-confirmation .checkmark {
      background: $primary--color !important;
    }
  }

  .auth0-lock-error .auth0-lock-input-wrap {
    border-color: $purple !important;
  }
}
