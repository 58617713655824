/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

// these variables were exposed by mistake and are no longer exposed by material
$dark-primary-text: rgb(0 0 0 / 87%);
$light-primary-text: white;
$jobCloud-primary: (
  50: #f9f9fa,
  100: #e6e6ea,
  200: #d2d3d9,
  300: #b8bac3,
  400: #989ba8,
  500: #7f8393,
  600: #585d72,
  700: #313751,
  800: #202333,
  900: #0e0e14,
  A100: #e6e6ea,
  A200: #d2d3d9,
  A400: #989ba8,
  A700: #0e0e14,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
$jobCloud-red: (
  50: #fff3f0,
  100: #fdd8ce,
  200: #f9ae9a,
  300: #f28264,
  400: #e65c37,
  500: #d43f17,
  600: #b2320f,
  700: #812308,
  800: #561001,
  900: #370a01,
  A100: #fdd8ce,
  A200: #f9ae9a,
  A400: #e65c37,
  A700: #812308,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
