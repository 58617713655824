/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/variables';

@keyframes btn-spinner {
  to {
    transform: rotate(360deg);
  }
}

%btn-spinner,
.btn-spinner::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-top-color: #000;
  animation: btn-spinner 0.8s linear infinite;
  z-index: variables.$z-index-button;
}

.btn-icon-spinner::before {
  @extend %btn-spinner;

  margin-left: -18px;
}
