/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/variables';
@use 'styles/colors';

.list-items {
  &__no-border {
    border: none !important;
  }

  &.active {
    background: colors.$white;
    border-left: 3px solid colors.$primary--color;
  }

  &.display-cursor {
    padding: 0.5rem;
    border-bottom: 1px solid colors.$color-light-gray;
    display: flex;
    justify-content: space-between;
  }

  &:hover {
    background-color: colors.$light-color;
  }

  [class^='column-'],
  [class*='column-'] {
    flex-direction: column;
  }

  .flex-row {
    flex-direction: row;
  }

  .column-1 {
    flex: 4;
  }

  .column-2 {
    flex: 1;

    svg {
      margin-top: 0.7em;
    }
  }

  .column-3 {
    flex: 3;
    text-align: right;
  }

  .column-4 {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .title {
    padding: 0.5em 0 0;
    word-break: break-all;
  }

  .description {
    font-size: 0.8em;
    color: silver;
    padding: 0.2em 0 0.5em;
  }
}

@media screen and (max-width: variables.$media-breakpoint-tablet) {
  .column-1 {
    max-width: 50%;
  }
}
