/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/variables';
@use 'styles/typography';

.mat-mdc-tab-link {
  text-transform: uppercase;
  padding: 0 1.143em !important;
  min-width: auto !important;
}

.mat-mdc-tab-nav-bar {
  border-bottom: none !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label,
.mat-mdc-tab-link,
.mat-mdc-tab-group {
  font-family: typography.$font-stack;
  letter-spacing: 1.28px; // [TODO] variable
}

.mat-mdc-tab-group {
  font-family: typography.$font-stack;
  letter-spacing: 0;

  h3 {
    font-weight: 600;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label-active {
  color: $primary--color;
}
