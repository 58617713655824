/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/variables';
@use 'styles/colors';
@use 'styles/mixins';

$btn-clear__input__padding: variables.$size-xxs variables.$size-0;
$btn-clear__input__margin-right: variables.$size-xxs;
$btn-clear__input__font-size: variables.$size-l;
$button-border-radius: variables.$box-button-radius;
$mat-fab-pos-top: 5px;
$mat-fab-pos-left: 6px;

.mat-mdc-raised-button {
  text-transform: capitalize !important;
  font-weight: 400;
}

.btn {
  padding: 0 10px;
  cursor: pointer;

  &.btn-link {
    color: colors.$primary--color;
    font-size: 11px;
  }

  &-clear__input {
    padding: $btn-clear__input__padding !important;
    margin-right: $btn-clear__input__margin-right !important;
    font-size: $btn-clear__input__font-size !important;
    font-weight: bold !important;
    line-height: normal !important;
    height: auto !important;

    &--input {
      // JMP-3592 hack to avoid overlap between text and clear-input element
      width: calc(100% - 2.2rem) !important;
    }
  }
}

// Specific to application Profile
section {
  // .mdc-button__label {
  //   white-space: pre-wrap;
  // }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .actions-button-container .mdc-button__label {
    display: inline-block;
    position: absolute;
    top: $mat-fab-pos-top;
    left: $mat-fab-pos-left;
    padding: 0 !important;
  }
}

.button-infos {
  display: unset;
  cursor: pointer;
  height: variables.$size-m;
  width: variables.$size-m;
  font-size: variables.$size-s;
  margin-left: variables.$size-xxs;
  background: colors.$primary--color;
  border-radius: 50%;
  color: white;
  text-align: center;
  border: 0;
  box-shadow:
    0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);

  &:active {
    box-shadow: unset;
  }
}

.button-as-link {
  width: auto !important;
  color: colors.$primary--color;
  text-transform: capitalize;
  padding: 0 !important;
  font-weight: 600;
  line-height: 1;

  @include mixins.reset-appearance;
}

.button--simple {
  color: colors.$primary--color;
  text-transform: capitalize;
  font-weight: 600;
  padding: 0 !important;
}
