/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/variables';

$margin-sizes: (
  "0": 0,
  "xxs": 4px,
  "xs": 8px,
  "s": 12px,
  "m": 16px,
  "l": 20px,
  "xl": 24px,
  "xxl": 28px,
  "xxxl": 32px
);

$directions: (
  "t": "top",
  "b": "bottom",
  "l": "left",
  "r": "right"
);

@each $size, $value in $margin-sizes {
  @each $direction, $css-property in $directions {
    .margin-#{$css-property}-#{$size} {
      margin-#{$css-property}: $value !important;
    }
    .padding-#{$css-property}-#{$size} {
      padding-#{$css-property}: $value !important;
    }
  }
}
