/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';
@use 'styles/variables';

$gray-primary: colors.$gray-primary;
$red-error: colors.$red-error;
$form-field-margin: variables.$margin-l variables.$margin-0 variables.$margin-s; // 20px 0 10px;
$form-field-wrapper-margin: variables.$margin-xs variables.$margin-xs;
$form-field-weight: 600;

.mat-mdc-form-field {
  line-height: normal;
}

.form-field-label {
  margin: $form-field-margin;
  display: block;
  font-weight: $form-field-weight;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-text-field-wrapper {
  // @TODO: remove these annoying properties, we shouldn't have !important in global scss files
  margin: $form-field-wrapper-margin !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick {
  color: $primary--color;
}

// @TODO: remove these annoying properties, we shouldn't have !important in global scss files
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field-suffix {
  top: 0 !important;
  right: 5px;
  color: $gray-primary !important;
  font-style: normal;
  width: 25px !important;
  height: 25px !important;
}

//@extend
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.upgrade-editable-notes .mat-mdc-text-field-wrapper {
  margin: 0 !important;
}

.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-invalid.mat-mdc-form-field-invalid
  .mat-mdc-form-field-outline-thick,
.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label,
.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label.mat-accent,
.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label .mat-mdc-form-field-required-marker,
.mat-mdc-form-field-error {
  color: $red-error;
}

.mat-mdc-form-field-invalid .mat-mdc-input-element,
.mat-mdc-form-field.mat-warn .mat-mdc-input-element {
  caret-color: $red-error;
}
