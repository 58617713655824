/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';

$gray-lighter: colors.$gray-lighter;
$red: colors.$red;

.message-panel {
  border: 1px solid $gray-lighter;
  padding: 10px 15px;
  border-radius: 4px;

  &.message-warning {
    color: $red;
    background-color: $gray-lighter;
    border-color: $gray-lighter;
  }
}
