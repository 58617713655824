/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';
@use 'styles/variables';
@use 'styles/mixins';

$text-global-lineheight: variables.$global-lineheight;
$text-global-font-size: variables.$global-font-size;
$white: colors.$white;
$gray-primary: colors.$gray-primary;
$primary--color: colors.$primary--color;
$purple: colors.$purple;
$red: colors.$red;

/** Headings **/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.text-button {
  color: rgb(0 0 0 / 87%);
}

h2,
.h2 {
  font-size: 1.285rem;
  font-weight: 600;
  line-height: 1.928rem;
}

h6,
.h6 {
  font-size: 0.857rem;
  font-weight: 600;
  line-height: 1.285rem;
  text-transform: uppercase;
}

p,
.p {
  font-size: 1em;
  font-weight: 200;
  line-height: 1.333rem;
}

p.tiny {
  font-size: 0.857rem;
  font-weight: 600;
  line-height: 1.285rem;
}

/**
* Body text
*/
.text-body-1,
.text-body-2,
.text-subtitle-1,
.text-subtitle-2,
.text-caption,
.text-overline {
  color: colors.$font-text-color;
}

.text-body-1 {
  font-size: 1.129rem;
  letter-spacing: 0.5px;
  line-height: 2rem;
}

.text-body-2 {
  font-size: 1rem;
  letter-spacing: 0.25px;
  line-height: 1.428rem;
}

.text-subtitle-1 {
  font-size: 1.129rem;
  letter-spacing: 0.15px;
}

.text-subtitle-2 {
  font-size: 1rem;
  letter-spacing: 0.1px;
  line-height: 1.714rem;
}

.text-caption {
  font-size: 0.846rem;
  letter-spacing: 0.4px;
  line-height: 1.143rem;
}

.text-overline {
  font-size: 0.846rem;
  letter-spacing: 0.142rem;
  line-height: 1.143rem;
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
  cursor: pointer;
}

/**
* Button
*/
.text-button {
  font-size: 0.979rem;
  font-weight: 600;
  letter-spacing: 0.071rem;
  line-height: 1.143rem;
  text-transform: uppercase;
}

.text-noresults {
  display: block;
  color: $red;
  font-size: 0.846rem;
}

/**
* Primary color
*/
.text-primary {
  color: $primary--color;
}

.text-secondary {
  color: $purple;
}

.text-white {
  color: $white !important;
}

.text-gray {
  color: $gray-primary !important;
}

/**
* Font weight
*/
.font-weight-100 {
  @include mixins.font-weight(100);
}

.font-weight-300 {
  @include mixins.font-weight(300);
}

.font-weight-600 {
  @include mixins.font-weight(600);
}

.font-weight-800 {
  @include mixins.font-weight(800);
}

/**
* Font weight
*/
.font-size-14 {
  @include mixins.font-size(14);
}

.font-size-12 {
  @include mixins.font-size(12);
}

.font-size-11 {
  @include mixins.font-size(11);
}

.font-size-10 {
  @include mixins.font-size(10);
}

.font-size-30 {
  @include mixins.font-size(30);
}

.font-size-18 {
  @include mixins.font-size(18);
}

.font-size-16 {
  @include mixins.font-size(16);
}


.text-slim {
  font-weight: 300;
}

.text-tiny {
  font-size: 0.875rem;
}

.medium-icon {
  font-size: 1.5rem;
}
