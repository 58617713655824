/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/colors';

$red-lighter: colors.$red-lighter;
$red-darken: colors.$red-darken;
$purple-lighter: colors.$purple-lighter;
$purple-darken: colors.$purple-darken;
$lighter-blue: colors.$lighter-blue;
$primary--color: colors.$primary--color;
$green-darken: colors.$green-darken;
$green-lighter: colors.$green-lighter;
$green-light: colors.$green-light;

.mat-mdc-chip {
  font-size: 12px !important;
  padding: 5px 10px !important;

  .mat-icon {
    margin-right: 5px !important;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.mat-mdc-chip.mat-chip-status {
  min-height: 24px;

  &.status-loader,
  &.status-loader:hover {
    background-color: transparent !important;

    .spinner.spinner-small {
      margin-top: 0 !important; //TODO Remove important accross the app
    }
  }

  &.status-new {
    background-color: $purple-lighter !important;
    color: $purple-darken !important;
  }

  &.status-shortlist {
    background-color: $lighter-blue !important;
    color: $primary--color !important;
  }

  &.status-interview {
    background-color: $green-lighter !important;
    color: $green-darken !important;
  }

  &.status-offer {
    background-color: $green-lighter !important;
    color: $green-darken !important;
  }

  &.status-hired {
    background-color: $green-lighter !important;
    color: $green-darken !important;
  }

  &.status-decline {
    background-color: $red-lighter !important;
    color: $red-darken !important;
  }
}
