/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/variables';
@use 'styles/typography';

.mat-mdc-card {
  border-radius: variables.$box-default-radius !important;
  font-family: typography.$font-stack;
}
