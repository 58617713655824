/*********************************************************************
 * *******************************************************************
 * IMPORTANT: DO NOT MODIFY THIS FILE
 * Any necessary modifications should be made in the 'ui-lib' instead.
 * *******************************************************************
 *********************************************************************/

@use 'styles/variables';
@use 'styles/colors';

p.paragraph {
  font-weight: 400;
  margin-top: variables.$normal-rem;
  color: colors.$color-neutral-800;

  &__smaller-text {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: variables.$normal-rem;
    color: colors.$color-neutral-800;
  }
}
